
/* DESKTOP HEADER */
.header-3-desktop {
    display: block;
    border-bottom: 1px solid #E5E5E5;

    .header-wrap {
        padding: 15px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link-item {
                font-size: 14px;
                font-weight: 600;
                color: #000;
                text-decoration: none;
            }

            .link-item:hover {
                color: #5BBDC8;
            }
        }

        .center-section {
            padding: 0px 140px;

            .company-logo {
                width: 180px;
                height: auto;
                padding-right: 20px;
            }

            .agent-logo {
                width: 150px;
                height: auto;
                padding-left: 20px;
                border-left: 2px solid rgba($color: #000000, $alpha: 0.3);
            }
        }

        .right-section {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link-item {
                font-size: 14px;
                font-weight: 600;
                color: #000;
                text-decoration: none;
            }

            .link-item:hover {
                color: #5BBDC8;
            }
        }
    }
}

/* MOBILE HEADER */
.header-3-mobile {
    display: none;
    padding: 20px 0px;

    .header-links {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-box {
            img {
                width: 180px;
                height: auto;
            }
        }

        .navigation-links {
            .logo-box {
                img {
                    width: 180px;
                    height: auto;
                }
            }

            .navbar {
                padding: 0px;
            }

            .main-menu {

                .menu-link {
                    font-size: 14px;
                    color: #323232;
                    font-weight: 600;
                    padding: 0px 20px;
                    text-transform: uppercase;
                }

                .menu-link:hover {
                    color: #5BBDC8;
                }

                .call-link {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: #323232;
                    margin-left: 10px;
                    margin-top: 0px;

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 5px;
                    }
                }

                .call-link:hover {
                    color: #5BBDC8;

                    svg {
                        fill: #5BBDC8;
                    }
                }

                .menu-link:first-child {
                    padding-left: 0px;
                }
            }
        }
    }
}

/* FOOTER */
.footer-3 {
    background-color: rgba($color: #5BBDC8, $alpha: 0.2);

    .footer-main {
        padding: 30px 0px;

        .agent-name {
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 0px;
            color: #000;
        }

        .agent-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;

            .details-box {
                h5 {
                    font-size: 18px;
                    font-weight: 700;
                    color: #000;
                    margin-bottom: 0px;
                }

                p {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin-bottom: 0px;
                    margin-top: 5px;
                }

                a {
                    font-size: 15px;
                    font-weight: 500;
                    color: #000;
                    margin-bottom: 0px;
                    display: block;
                    text-decoration: none;
                    margin-top: 5px;
                }
            }
        }

        .footer-small-info {
            margin-top: 30px;
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 18px;
        }
    }

    .copyright-main {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0px;
        border-top: 1px solid rgba($color: #000000, $alpha: 0.3);

        h5 {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 0px;
            color: #000;

            a {
                color: #323232;
                text-decoration: underline;
            }
        }

        .links {
            display: flex;
            align-items: center;

            .social-media-box {
                display: block;
                height: 35px;
                width: 35px;
                border-radius: 50%;
                border: 2px solid #323232;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 15px;
            }

            .social-media-box:last-child {
                margin-right: 0px;
            }

            .social-media-box:hover {
                background-color: #323232;

                svg {
                    fill: #FFF;
                }
            }
        }
    }
}